import { AuthState } from '@aws-amplify/ui-components';
import { Box, Container, Flex, Icon, Link, Menu, MenuButton, Text } from '@chakra-ui/react';
import { routerActions } from 'connected-react-router';
import { Button, CaretDownIcon, LogOutIcon, Menu as EgMenu, Popover, Position } from 'evergreen-ui';
import React, { memo, useEffect, useState, useRef } from 'react';
import {
  FiBell,
  FiCalendar,
  FiFeather,
  FiUser,
  FiThumbsUp,
  FiFileText,
  FiHome,
  FiDollarSign
} from 'react-icons/fi';
import { GrHostMaintenance } from "react-icons/gr";
import { useAppDispatch, useAppSelector } from '../hooks';
import { signOutAsync } from '../slice/userSlice';
import './shell.scss';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { ASSETS_IMG } from '../utils/assets';

const NavItem = ({ icon, title, active, onClick }: any) => {
  return (
    <Flex mt={30} flexDir="column" w="100%">
      <Menu placement="right">
        <Link
          backgroundColor={active ? '#cbe7ff' : 'inherit'}
          p={3}
          borderRadius={2}
          _hover={{ textDecor: 'none', backgroundColor: '#e8f4ff' }}
          w={'auto'}
          onClick={onClick}
        >
          <Flex>
            <Icon as={icon} fontSize="xl" color={active ? '#82AAAD' : 'gray.500'} />
            <Text ml={5} display={'flex'}>
              {title}
            </Text>
          </Flex>
        </Link>
      </Menu>
    </Flex>
  );
};

const navs = [
  { icon: FiHome, title: '企業', active: false, pathname: '/companies' },
  { icon: FiHome, title: '企業契約', active: false, pathname: '/agreements' },
  { icon: FiUser, title: '学習者', active: false, pathname: '/learners' },
  { icon: FiFeather, title: '教師', active: false, pathname: '/teachers' },
  { icon: FiCalendar, title: 'レッスン', active: false, pathname: '/lessons' },
  { icon: FiThumbsUp, title: 'フィードバック', active: false, pathname: '/feedbacks' },
  { icon: FiFileText, title: '申し送り', active: false, pathname: '/lesson_notes' },
  { icon: GrHostMaintenance, title: 'メンテナンス', active: false, pathname: '/maintenance' },
  { icon: FiBell, title: 'お知らせ', active: false, pathname: '/notices' },
  { icon: FiDollarSign, title: '教師請求', active: false, pathname: '/payments_teacher' },
];
export interface IShellProps {
  children: React.ReactNode;
}

export var orgKey = React.createContext('kokoka');
export var currentUserId = React.createContext('');

const getOrgKeyOrCurrentUserId = async (attributeName: string = 'currentUserId') => {
  const authenticatedUser = await Auth.currentAuthenticatedUser().catch((e) => null);
  if (authenticatedUser) {
    const orgKey = await authenticatedUser.attributes['custom:companyId'];
    const currentUserId = await authenticatedUser.attributes['sub'];
    return attributeName === 'orgKey' ? orgKey : currentUserId;
  }
  return '';
};

export const Shell: React.NamedExoticComponent<IShellProps> = memo(({ children }) => {
  const dispatch = useAppDispatch();
  const historyState = useAppSelector((state) => state.router);
  const userState = useAppSelector((state) => state.user);
  const [companyId, setCompanyId] = useState<any>('くらしスタディ はなすプラス');
  const onClickBrand = () => dispatch(routerActions.push({ pathname: '/top' }));
  const history = useHistory();

  const onClickSignout = async () => {
    await dispatch(signOutAsync());
  };

  useEffect(() => {
    getOrgKeyOrCurrentUserId().then((teacherId) => {
      currentUserId = React.createContext(teacherId);
    });
    return;
  });

  return (
    <>
      <Box
        display="flex"
        height="60px"
        borderBottom="1px solid #ddd"
        justifyContent="space-between"
        boxShadow={'sm'}
        alignItems="center"
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex={500}
        bg="white"
      >
        <Box alignItems="center" display="flex" flexWrap="wrap">
          <Box className="Logo" onClick={onClickBrand} style={{ cursor: 'pointer' }}>
            <img src={ASSETS_IMG.logo01} alt={companyId} />
          </Box>
          <br />
          <sub style={{ color: '#3f83bf', marginLeft: '5px', bottom: 'unset' }}>
            {companyId !== undefined && companyId.length > 0 ? ' | ' : ''} 運用管理システム
          </sub>
        </Box>
        <Box hidden={!(userState.authState === AuthState.SignedIn && userState.user)}>
          <Popover
            position={Position.BOTTOM_LEFT}
            bringFocusInside={true}
            content={() => (
              <EgMenu>
                <EgMenu.Group>
                  <EgMenu.Item icon={LogOutIcon} onClick={onClickSignout} intent="danger">
                    Signout
                  </EgMenu.Item>
                </EgMenu.Group>
              </EgMenu>
            )}
          >
            <Button iconAfter={CaretDownIcon} marginRight={12} intent="info">
              {userState.user?.email}
            </Button>
          </Popover>
        </Box>
      </Box>
      <Flex>
        <Flex
          pos="sticky"
          borderRight="1px solid #ddd"
          boxShadow="sm"
          minW="200px"
          flexDir="column"
          justifyContent="space-between"
          top="0"
          h="100vh"
          overflowY="auto"
          hidden={!(userState.authState === AuthState.SignedIn && userState.user)}
        >
          <Flex className="scrollable-sidebar" flexDir="column" marginTop={'60px'} as="nav">
            {navs.map((nav) => (
              <NavItem
                key={nav.title}
                icon={nav.icon}
                title={nav.title}
                active={nav.pathname === historyState.location.pathname}
                onClick={() => {
                  dispatch(routerActions.push(nav.pathname));
                }}
              />
            ))}
          </Flex>
        </Flex>
        <Container
          className="Shell-children main-content"
          marginTop={'75px'}
          maxWidth={'100vw'}
          marginX={'2em'}
        >
          {children}
        </Container>
      </Flex>
    </>
  );
});
