import { I18n } from '@aws-amplify/core';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { routerActions } from 'connected-react-router';
import React, { lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import './App.scss';
import { useAppDispatch, useAppSelector } from './hooks';
import { COGNITO_TRANSLATIONS } from './i18';
import { Shell } from './pages/shell';
import { isLoading, stopLoading } from './slice/loadingSlice';
import { CognitoPayload, signIn, signOutAsync } from './slice/userSlice';
import { format } from 'date-fns';
import Spinner from './components/Spinner';
import { ErrorBoundaryWrapper, ErrorPage } from './pages/error';
import { ERROR_CODE } from './constant/errorCode';
import { ERROR_MESSAGE } from './constant/message';
const Top = lazy(() => import('./pages/top/top'));
const Signin = lazy(() => import('./pages/signin/signin'));
const Companies = lazy(() => import('./pages/companies/index'));
const Company = lazy(() => import('./pages/company/index'));
const Agreements = lazy(() => import('./pages/payments_company/index'));
const Agreement = lazy(() => import('./pages/payment_company/index'));
const Notices = lazy(() => import('./pages/notices/notices'));
const Feedbacks = lazy(() => import('./pages/feedback/feedback'));
const LessonNotes = lazy(() => import('./pages/lesson_notes/lesson_notes'));
const Maintenance = lazy(() => import('./pages/maintenance/index'));
const Lessons = lazy(() => import('./pages/lessons/index'));
const Lesson = lazy(() => import('./pages/lesson/index'));
const Teachers = lazy(() => import('./pages/teachers/teachers'));
const Teacher = lazy(() => import('./pages/teacher/index'));
const Learners = lazy(() => import('./pages/learners/index'));
const Learner = lazy(() => import('./pages/learner/index'));
const PaymentsCompany = lazy(() => import('./pages/payments_company/index'));
const TeacherLessonFees = lazy(() => import('./pages/payments_teacher/index'));
const TeacherLessonFee = lazy(() => import('./pages/payment_teacher/index'));

I18n.putVocabulariesForLanguage('ja', COGNITO_TRANSLATIONS);
const PrivateRoute = ({ render, userState, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={
        userState.authState === AuthState.SignedIn && userState.user
          ? render
          : () => <Redirect to="/signin" />
      }
    />
  );
};
export const Loading = () => <Spinner />;

const extractCognitoPayload = (authenticatedUser: any): CognitoPayload => {
  try {
    return {
      'cognito:username': authenticatedUser.signInUserSession.idToken.payload['cognito:username'],
      'cognito:companyId': authenticatedUser.attributes['custom:companyId'],
      email: authenticatedUser.signInUserSession.idToken.payload.email,
      exp: authenticatedUser.signInUserSession.idToken.payload.exp,
    };
  } catch (error) {
    throw error;
  }
};

const App = () => {
  const userState = useAppSelector((state) => state.user);
  const isloading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const init = async () => {
      const authenticatedUser = await Auth.currentAuthenticatedUser().catch((e) => null);
      if (authenticatedUser) {
        const expayload = extractCognitoPayload(authenticatedUser);
        dispatch(
          signIn({
            authState: AuthState.SignedIn,
            user: expayload,
          }),
        );
        dispatch(stopLoading());
      } else {
        await dispatch(signOutAsync());
        dispatch(stopLoading());
        dispatch(routerActions.push('/signin'));
      }
    };
    init();
    return onAuthUIStateChange((nextAuthState, authenticatedUser) => {
      if (nextAuthState === AuthState.SignedIn && authenticatedUser) {
        dispatch(
          signIn({
            authState: AuthState.SignedIn,
            user: extractCognitoPayload(authenticatedUser),
          }),
        );
      }
    });
  }, [dispatch]);
  return isloading ? (
    <ErrorBoundaryWrapper>
      <Loading />
    </ErrorBoundaryWrapper>
  ) : (
    <ErrorBoundaryWrapper>
      <React.Suspense fallback={<Loading />}>
        <Shell>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return userState.authState === AuthState.SignedIn && userState.user ? (
                  <Redirect to="/top" />
                ) : (
                  <Redirect to="/signin" />
                );
              }}
            />
            <Route
              exact
              path="/signin"
              render={() => {
                return userState.authState === AuthState.SignedIn && userState.user ? (
                  <Redirect to="/top" />
                ) : (
                  <Signin />
                );
              }}
            />
            <PrivateRoute exact path="/top" render={() => <Top />} userState={userState} />
            <PrivateRoute
              exact
              path="/companies"
              render={() => <Companies />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/companies/:companyId"
              render={() => <Company />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/agreements"
              render={() => <Agreements />}
              userState={userState}
            />
              <PrivateRoute
              exact
              path="/agreement/:payment_id/:company_id"
              render={() => <Agreement />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/learners"
              render={() => <Learners />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/learners/:companyId/:learnerId"
              render={() => <Learner />}
              userState={userState}
            />
            <PrivateRoute exact path="/lessons" render={() => <Lessons />} userState={userState} />
            <PrivateRoute
              exact
              path="/lesson/:companyId/:lessonId"
              render={() => <Lesson />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/feedbacks"
              render={() => <Feedbacks />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/lesson_notes"
              render={() => <LessonNotes />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/maintenance"
              render={() => <Maintenance />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/teachers"
              render={() => <Teachers />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/teachers/:teacherId"
              render={() => <Teacher />}
              userState={userState}
            />
            <PrivateRoute exact path="/notices" render={() => <Notices />} userState={userState} />
            <PrivateRoute
              exact
              path="/payments_teacher"
              render={() => <TeacherLessonFees />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/payments_teacher/:teacher_id/:year_month"
              render={() => <TeacherLessonFee />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/payments_company"
              render={() => <PaymentsCompany />}
              userState={userState}
            />
            <Route
              exact
              path="*"
              render={() => {
                return (
                  <ErrorPage
                    errorCode={ERROR_CODE.HTTP_404}
                    error={new Error(ERROR_MESSAGE.HTTP_404.ja + ` url:${document.location.href}`)}
                    message={ERROR_MESSAGE.HTTP_404.ja}
                  />
                );
              }}
            />
          </Switch>
        </Shell>
      </React.Suspense>
    </ErrorBoundaryWrapper>
  );
};
export default App;

export const exchangeTimeZone = (datedime: Date) => {
  // ローカル時間に変更
  let localDatetime = datedime.toLocaleString();
  // 出力用にフォーマット
  let fromattedTime = format(Date.parse(localDatetime), 'yyyy/MM/dd HH:mm:ss');
  return fromattedTime;
};
export const exchangeTimeZone2 = (datetime: Date) => {
  // 出力用にフォーマット(入力値をそのまま出力)
  let fromattedTime =
    datetime.getUTCFullYear() +
    '-' +
    (datetime.getUTCMonth() + 1).toString().padStart(2, '0') +
    '-' +
    datetime.getUTCDate().toString().padStart(2, '0') +
    'T' +
    datetime.getUTCHours().toString().padStart(2, '0') +
    ':' +
    datetime.getUTCMinutes().toString().padStart(2, '0') +
    ':' +
    datetime.getUTCSeconds().toString().padStart(2, '0') +
    '.000Z';

  return fromattedTime;
};
export const exchangeTimeZone3 = (datetime: Date) => {
  let today = datetime;
  if (typeof datetime == 'string') {
    today = new Date(today);
  }
  // 出力用にフォーマット(入力値をそのまま出力)
  let fromattedTime =
    today.getUTCFullYear() +
    '-' +
    (today.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    today.getDate().toString().padStart(2, '0') +
    'T' +
    today.getHours().toString().padStart(2, '0') +
    ':' +
    today.getMinutes().toString().padStart(2, '0') +
    ':' +
    today.getSeconds().toString().padStart(2, '0');
  return fromattedTime;
};
